import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sidan hittades inte" />
    <h1>Sidan kunde du letade efter kan tyvärr inte hittas...</h1>
  </Layout>
);

export default NotFoundPage;
